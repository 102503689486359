/* src/App.module.css */

.apiKeyInput {
  width: 100px;
  height: 25px;
  margin: 0 5px;
}

.AppHeader {
  position: fixed; /* Key property to fix position */
  top: 0; /* Align top edge of header with top of viewport */
  left: 0; /* Align left edge of header with left of viewport */
  width: 100%; /* Ensure header stretches across full viewport width */
  background-color: #D6B493; /* Optionally ensure the header has a background */
  z-index: 1000; /* Ensure header stays on top of other content */
  box-shadow: 0 2px 4px rgba(0,0,0,.1); /* Optional: Adds shadow for some depth */
  display: flex;
  justify-content: space-between; /* Maintain existing layout */
  padding: 5px; /* Maintain existing padding */
  align-items: center; /* Maintain vertical alignment of items */
}

.App {
  background-color: #D6B493; /* Changed to FatCat Tan background #D6B493 */
  min-height: 100vh; /* Extend background to the bottom of the screen */
  padding-top: 95px; /* Adjust this value based on your header's height */
  padding-bottom: 150px; /* Adjust based on the actual height of your footer */
}

button {
  background-color: #E6844C; /* Pink color for buttons */
  color: black;
  border: 1px solid black; /* Added dark border */
  padding: 4px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 7px; /* Added 7px margin */
}

button:hover {
  background-color: #E5E076; /* Darker shade of pink on hover */
}

.chatFooter {
  position: fixed; /* Fix position relative to the viewport */
  left: 0;
  bottom: 0; /* Align at the bottom */
  width: 100%; /* Full width */
  background-color: #D6B493; /* Match your app's theme */
  color: white;
  text-align: right; /* Align the content to the right */
  padding: 10px; /* Add some padding */
  box-shadow: 0 -2px 4px rgba(0,0,0,.1); /* Optional: Adds shadow for visual separation */
}

.App-header {
  display: flex;
  justify-content: space-between; /* Align items to the ends */
  padding: 7px; /* Added 7px padding */
  align-items: center; /* Align items vertically */
}

ul {
  list-style: none;
  padding: 7px;
}

li {
  margin: 5px 0;
  padding-left: 7px; /* Ensure padding at the far left of each new line */
}

li.user {
  color: rgb(0, 0, 154);
  padding-left: 7px; /* Enforcing padding for text on new lines */
}

li.assistant {
  color:  black; /* green; */
  padding-left: 7px;
}

form {
  display: flex;
  flex-direction: column; /* Changed to column layout */
}

input, textarea {
  flex-grow: 1;
  padding: 10px;
  margin: 7px 0; /* Updated margin */
  width: calc(100% - 22px); /* Adjust width for padding */
}

.button-container {
  display: flex;
  justify-content: flex-start; /* Align buttons to the start */
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  margin: 7px 0; /* Added 7px margin */
}

.fatCatChatTitle {
  font-size: 1.2rem;
}
